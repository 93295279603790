
































import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default defineComponent({
  name: 'UOttawaPostLab2Introduction',
  components: {AiLoadingOverlay, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        introductionAnswer: null,
        language: 'en',
      },
      questions: [
        {
          en: 'In the space below, write an introductory paragraph for this experiment. Remember to highlight all theory and background information relevant to this lab.',
          fr: "Dans l'espace ci-dessous, rédigez un paragraphe d'introduction pour cette expérience. N'oubliez pas de mettre en évidence toutes les théories et informations de base pertinentes pour ce laboratoire.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
